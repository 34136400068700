"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { NavLink } from "react-router-dom";
import { useRoute } from "@memed/react-router-mapping";
import {
  Basket,
  Flask,
  MedicalCard,
  UserProfile
} from "@patients-ui/icons";
import { usePrescriptionContext } from "~/views/contexts/PrescriptionContext";
import styles from "./styles.module.scss";
export const NavBar = () => {
  const { route } = useRoute();
  const {
    isRejectedTerms,
    isPartnerBlocked,
    isPreviewMode,
    hasEan,
    isExamsExclusiveFlow
  } = usePrescriptionContext();
  const getRoute = (path) => isRejectedTerms ? route("privacy-policy") : route(path);
  return /* @__PURE__ */ jsx("div", { className: styles.navbar_container, children: /* @__PURE__ */ jsxs("ul", { className: styles.navbar_list, children: [
    isExamsExclusiveFlow && /* @__PURE__ */ jsx("li", { children: /* @__PURE__ */ jsxs(NavLink, { to: getRoute("exclusive-listing"), title: "Agendar", children: [
      /* @__PURE__ */ jsx(Flask, { height: 24 }),
      "Agendar"
    ] }) }),
    hasEan && !isPartnerBlocked && /* @__PURE__ */ jsx("li", { children: /* @__PURE__ */ jsxs(NavLink, { to: getRoute("drugsstore"), title: "Comprar", children: [
      /* @__PURE__ */ jsx(Basket, {}),
      "Comprar"
    ] }) }),
    /* @__PURE__ */ jsx("li", { children: /* @__PURE__ */ jsxs(NavLink, { to: route("prescription"), title: "Receita", children: [
      /* @__PURE__ */ jsx(MedicalCard, {}),
      "Receita"
    ] }) }),
    /* @__PURE__ */ jsx("li", { children: /* @__PURE__ */ jsxs(NavLink, { to: route("privacy"), title: "Privacidade", style: { pointerEvents: isPreviewMode ? "none" : "initial" }, children: [
      /* @__PURE__ */ jsx(UserProfile, {}),
      "Privacidade"
    ] }) })
  ] }) });
};
