"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useRoute } from "@memed/react-router-mapping";
import { usePbmReducer } from "./reducer";
import { vtexApi } from "~/services/vtexApi";
import { pbmApi } from "~/services/pbmApi";
import { xRay } from "~/utils/tracking";
const PbmContext = createContext({});
export const PbmProvider = () => {
  const [{
    orderForm,
    estimate,
    isLoading
  }, dispatch] = usePbmReducer();
  const navigate = useNavigate();
  const { orderFormId } = useParams();
  const { route } = useRoute();
  const bestitem = useMemo(() => {
    if (estimate && estimate.sellers) {
      const { sellers: [seller] } = estimate;
      return seller.items.reduce((previous, current) => previous.base_discount_percent > current.base_discount_percent ? previous : current);
    }
    return {};
  }, [estimate]);
  const getOrderFormPath = useCallback(() => {
    ["direct_sale_items_json", "items_json", "experiments", "experiments_string"].forEach((persistentKey) => xRay.deletePersistentData(persistentKey));
    return `${process.env.MEMED_VTEX}/checkout/?orderFormId=${orderFormId}&${xRay.createUrlParam()}#/cart`;
  }, [orderFormId]);
  const getData = useCallback(() => __async(void 0, null, function* () {
    if (orderFormId) {
      const { data: dataOrderForm, status } = yield vtexApi.getOrderForm(orderFormId);
      if (status === 200) {
        const { items, seller: sellerId } = dataOrderForm;
        if (items && items.length) {
          const { data: dataEstimate } = yield pbmApi.getEstimate({
            sellers: [
              {
                id: sellerId,
                items: [...items].map(({ ean }) => ean)
              }
            ]
          });
          dispatch({
            type: "COMPLETE_PBM",
            payload: {
              orderForm: dataOrderForm,
              estimate: dataEstimate
            }
          });
        }
        return;
      }
    }
    navigate(route("drugsstore"));
  }), [dispatch, navigate, orderFormId, route]);
  const getOptInVerify = useCallback((ean, seller, cpf) => __async(void 0, null, function* () {
    try {
      const { data: { has_done }, status } = yield pbmApi.getOptInVerify({ ean, seller, cpf });
      if (status === 200) {
        if (!has_done) {
          const { data, status: status2 } = yield pbmApi.getOptIn({ cpf, ean });
          if (status2 === 200) {
            return data;
          }
        }
      }
    } catch (e) {
      const { message } = e;
      console.error("Erro ao verificar opt-in:", message);
    }
    return {};
  }), []);
  const value = useMemo(() => ({
    bestitem,
    orderForm,
    estimate,
    isLoading,
    getOptInVerify,
    getOrderFormPath
  }), [
    bestitem,
    orderForm,
    estimate,
    isLoading,
    getOptInVerify,
    getOrderFormPath
  ]);
  useEffect(() => {
    getData();
  }, [getData]);
  return /* @__PURE__ */ jsx(PbmContext.Provider, { value, children: /* @__PURE__ */ jsx(Outlet, {}) });
};
export const usePbmContext = () => useContext(PbmContext);
