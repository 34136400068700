"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useReducer } from "react";
const initialState = {
  sellers: [],
  isLoading: true,
  hasError: false,
  filters: {
    orderBy: "shippingTime",
    sort: "ASC"
  },
  isSubmitting: false
};
const reducer = (oldState, { type, payload }) => {
  switch (type) {
    case "REQUEST_PENDING": {
      return __spreadProps(__spreadValues({}, oldState), {
        sellers: [],
        isLoading: true,
        hasError: false
      });
    }
    case "REQUEST_SUCCESS": {
      return __spreadProps(__spreadValues({}, oldState), {
        sellers: payload,
        isLoading: false
      });
    }
    case "REQUEST_ERROR": {
      return __spreadProps(__spreadValues({}, oldState), {
        hasError: true,
        isLoading: false
      });
    }
    case "SET_FILTERS": {
      return __spreadProps(__spreadValues({}, oldState), {
        filters: payload,
        isLoading: true
      });
    }
    case "SET_IS_SUBMITTING": {
      return __spreadProps(__spreadValues({}, oldState), {
        isSubmitting: payload
      });
    }
    default: {
      return oldState;
    }
  }
};
export default () => useReducer(reducer, initialState);
