"use strict";
import { Service } from "../service";
class GollumApi extends Service {
  constructor() {
    super(process.env.API_GOLLUM);
  }
  /**
   * Verifica se um usuario é elegível ao programa de geração de leads
   * @param {IGetLeadAvailabilityResponse} cep, eans
   * @returns {Promise}
   */
  getLeadAvailability(cep, ids) {
    return this.instance.get(`eligibility/check?cep=${cep}&item_ids=${ids}`);
  }
}
export const gollumApi = new GollumApi();
