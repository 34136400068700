"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, lazy } from "react";
import {
  BrowserRouter,
  Navigate,
  Routes,
  useLocation,
  useParams
} from "react-router-dom";
import { MappingProvider, useMap } from "@memed/react-router-mapping";
import FlagProvider from "@unleash/proxy-client-react";
import { InitialLoad } from "~/views/partials/Loader";
import Default from "./views/appearances/layouts/Default";
import WithNavbar from "./views/appearances/templates/WithNavbar";
import WithMemedLogo from "./views/appearances/templates/WithMemedLogo";
import { unleashClient } from "./utils/unleash";
import { PrescriptionProvider } from "./views/contexts/PrescriptionContext";
import { AddressProvider } from "./views/contexts/AddressContext";
import { SchedulingProvider } from "./views/contexts/SchedulingContext";
const CommonProviders = ({ children }) => /* @__PURE__ */ jsx(AddressProvider, { children: /* @__PURE__ */ jsx(FlagProvider, { unleashClient, startClient: false, children }) });
const Redirect = () => {
  const { pathname } = useLocation();
  return /* @__PURE__ */ jsx(Navigate, { to: `/p${pathname}` });
};
export default () => {
  const [MainRoutes, mainRoutes] = useMap([
    {
      path: "/p/:token",
      element: /* @__PURE__ */ jsx(PrescriptionProvider, {}),
      routes: [
        {
          path: "/",
          element: /* @__PURE__ */ jsx(WithNavbar, {}),
          routes: [
            {
              name: "home",
              label: "Home",
              Component: lazy(() => import(
                /* webpackChunkName: 'home' */
                "~/views/pages/Home"
              )),
              index: true
            },
            {
              name: "prescription",
              path: "receita",
              label: "Receita",
              Component: lazy(() => import(
                /* webpackChunkName: 'prescription' */
                "~/views/pages/Prescription"
              ))
            },
            {
              name: "privacy",
              path: "privacidade",
              label: "Privacidade",
              Component: lazy(() => import(
                /* webpackChunkName: 'privacy' */
                "~/views/pages/Privacy"
              ))
            },
            {
              name: "signature",
              path: "assinatura-digital",
              label: "Assinatura Digital",
              Component: lazy(() => import(
                /* webpackChunkName: 'signature' */
                "~/views/pages/DigitalSignature"
              ))
            },
            {
              name: "exclusive-listing",
              path: "agendamento-exames",
              label: "Agendamento Exclusivo",
              Component: lazy(() => import(
                /* webpackChunkName: 'exams' */
                "~/views/pages/Exams/ExclusiveListing"
              ))
            }
          ]
        },
        {
          path: "exames",
          routes: [
            {
              name: "labs-listing",
              path: "parceiros",
              label: "Listagem de parceiros",
              Component: lazy(() => import(
                /* webpackChunkName: 'labs-listing' */
                "~/views/pages/Exams/LabsListing"
              ))
            },
            {
              name: "exams-lab-listing",
              path: "exams-lab-listing",
              label: "Sele\xE7\xE3o de exames",
              Component: lazy(() => import(
                /* webpackChunkName: 'exams-lab-listing' */
                "~/views/pages/Exams/ExamsLabListing"
              ))
            },
            {
              name: "exams-unavailable",
              path: "indisponivel-na-sua-regiao",
              label: "Indispon\xEDvel na sua regi\xE3o",
              Component: lazy(() => import(
                /* webpackChunkName: 'exams-unavailable' */
                "~/views/pages/Exams/Unavailable"
              ))
            },
            {
              path: "agendamento",
              element: /* @__PURE__ */ jsx(SchedulingProvider, {}),
              routes: [
                {
                  name: "scheduling-exams-selection",
                  path: "selecao-de-exames",
                  label: "Sele\xE7\xE3o de exames",
                  Component: lazy(() => import(
                    /* webpackChunkName: 'scheduling-exams-selection' */
                    "~/views/pages/Exams/Scheduling/ExamsSelection"
                  ))
                },
                {
                  name: "scheduling-service-selection",
                  path: "tipos-de-atendimento",
                  label: "Sele\xE7\xE3o de exames",
                  Component: lazy(() => import(
                    /* webpackChunkName: 'scheduling-service-selection' */
                    "~/views/pages/Exams/Scheduling/ServiceSelection"
                  ))
                },
                {
                  name: "scheduling-lab-selection",
                  path: "selecao-de-laboratorio",
                  label: "Sele\xE7\xE3o de laborat\xF3rios",
                  Component: lazy(() => import(
                    /* webpackChunkName: 'scheduling-lab-selection' */
                    "~/views/pages/Exams/Scheduling/LabSelection"
                  ))
                },
                {
                  name: "scheduling-address",
                  path: "endereco",
                  label: "Endere\xE7o",
                  Component: lazy(() => import(
                    /* webpackChunkName: 'scheduling-address' */
                    "~/views/pages/Exams/Scheduling/Address"
                  ))
                },
                {
                  name: "scheduling-schedule",
                  path: "agendar",
                  label: "Hor\xE1rio",
                  Component: lazy(() => import(
                    /* webpackChunkName: 'scheduling-schedule' */
                    "~/views/pages/Exams/Scheduling/Schedule"
                  ))
                },
                {
                  name: "scheduling-summary",
                  path: "resumo",
                  label: "Resumo",
                  Component: lazy(() => import(
                    /* webpackChunkName: 'scheduling-summary' */
                    "~/views/pages/Exams/Scheduling/Summary"
                  ))
                },
                {
                  name: "scheduling-confirmation",
                  path: "confirmacao",
                  label: "Confirma\xE7\xE3o",
                  Component: lazy(() => import(
                    /* webpackChunkName: 'scheduling-confirmation' */
                    "~/views/pages/Exams/Scheduling/Confirmation"
                  ))
                },
                {
                  name: "scheduling-error",
                  path: "erro",
                  label: "Erro",
                  Component: lazy(() => import(
                    /* webpackChunkName: 'scheduling-error' */
                    "~/views/pages/Exams/Scheduling/Error"
                  ))
                }
              ]
            }
          ]
        },
        {
          name: "privacy-policy",
          path: "politica-privacidade",
          label: "Pol\xEDtica de privacidade",
          Component: lazy(() => import(
            /* webpackChunkName: 'privacy-policy' */
            "~/views/pages/PrivacyPolicy"
          ))
        }
      ]
    },
    {
      path: "/:token/*",
      Component: Redirect
    },
    {
      path: "/p/whatsapp/:token",
      Component: () => {
        const { token } = useParams();
        return /* @__PURE__ */ jsx(Navigate, { to: `/p/${token}?utm_source=whatsapp_pro`, replace: true });
      }
    }
  ]);
  const [ErrorRoutes, errorRoutes] = useMap([
    {
      path: "/p/:token",
      element: /* @__PURE__ */ jsx(WithMemedLogo, {}),
      routes: [
        {
          name: "prescription-deleted",
          path: "receita-deletada",
          label: "Receita Deletada",
          Component: lazy(() => import(
            /* webpackChunkName: 'prescription-deleted' */
            "~/views/pages/PrescriptionDeleted"
          ))
        },
        {
          name: "prescription-not-found",
          path: "receita-nao-encontrada",
          label: "Receita N\xE3o Encontrada",
          Component: lazy(() => import(
            /* webpackChunkName: 'prescription-not-found' */
            "~/views/pages/PrescriptionNotFound"
          ))
        },
        {
          name: "page-not-found",
          path: "*",
          label: "P\xE1gina N\xE3o Encontrada",
          Component: lazy(() => import(
            /* webpackChunkName: 'page-not-found' */
            "~/views/pages/NotFound"
          ))
        }
      ]
    }
  ]);
  return /* @__PURE__ */ jsx(BrowserRouter, { children: /* @__PURE__ */ jsx(MappingProvider, { routes: [mainRoutes, errorRoutes], children: /* @__PURE__ */ jsx(CommonProviders, { children: /* @__PURE__ */ jsx(Default, { children: /* @__PURE__ */ jsx(Suspense, { fallback: /* @__PURE__ */ jsx(InitialLoad, {}), children: /* @__PURE__ */ jsx(Routes, { children: /* @__PURE__ */ jsxs(Fragment, { children: [
    MainRoutes,
    ErrorRoutes
  ] }) }) }) }) }) }) });
};
