"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { useRoute } from "@memed/react-router-mapping";
import { usePrescriptionContext } from "../PrescriptionContext";
import { useAddressContext } from "../AddressContext";
import useCartReducer from "./reducer";
import { normalizeDirectSaleItemsJson } from "~/utils/formatters";
import { vtexApi } from "~/services/vtexApi";
import { pbmApi } from "~/services/pbmApi";
import { xRay } from "~/utils/tracking";
export const CartContext = createContext({});
export const CartProvider = () => {
  const [{
    sellers,
    filters,
    isLoading,
    hasError,
    isSubmitting
  }, dispatch] = useCartReducer();
  const { route } = useRoute();
  const navigate = useNavigate();
  const {
    prescribedItems,
    isRejectedTerms,
    hasEan,
    isPartnerBlocked
  } = usePrescriptionContext();
  const requestIsNotAllowed = isRejectedTerms || !hasEan || isPartnerBlocked;
  const {
    address: { postalCode }
  } = useAddressContext();
  const { token } = useParams();
  const setFilters = useCallback(
    (filters2) => {
      const updatedFilters = __spreadValues({}, filters2);
      Object.entries(updatedFilters).forEach(([filterKey, filterValue]) => {
        if (Boolean(filterValue) === false) {
          delete updatedFilters[filterKey];
        }
      });
      dispatch({ type: "SET_FILTERS", payload: updatedFilters });
    },
    [dispatch]
  );
  const setBasket = useCallback(
    (seller) => __async(void 0, null, function* () {
      dispatch({ type: "SET_IS_SUBMITTING", payload: true });
      try {
        const itemsAvailable = seller.products.filter((product) => product.availability !== "notFound");
        const orderItems = itemsAvailable.map((product) => ({
          seller: seller.id,
          id: product.id,
          quantity: product.quantity,
          prescriptionItemId: product.prescriptionItemId,
          isSpecialControl: product.isSpecialControl,
          hasEstimate: !!product.estimate
        }));
        const { shippingOptions } = seller.cart;
        const bestShipping = shippingOptions.find((shippingOption) => shippingOption.price === seller.shippingPrice);
        if (bestShipping) {
          const logisticsInfo = bestShipping.logisticsInfo.splice(0, itemsAvailable.length);
          const { data } = yield vtexApi.getOrderFormId({
            prescriptionToken: token,
            postalCode,
            orderItems,
            logisticsInfo,
            marketingData: {
              utmSource: "direct_sale"
            }
          });
          ["direct_sale_items_json", "items_json", "experiments", "experiments_string"].forEach((persistentKey) => xRay.deletePersistentData(persistentKey));
          xRay.persistData("has_lab_discount_applied", false);
          const orderFormPath = `${process.env.MEMED_VTEX}/checkout/?orderFormId=${data.orderFormId}&${xRay.createUrlParam()}#/cart`;
          if (orderItems.find((item) => item.hasEstimate)) {
            navigate(route("pbm", { orderFormId: data.orderFormId }));
            return;
          }
          window.location.assign(orderFormPath);
        }
      } catch (error) {
        console.error("Erro ao consultar o orderFormId!", error);
      }
      dispatch({ type: "SET_IS_SUBMITTING", payload: false });
    }),
    [dispatch, token, postalCode, navigate, route]
  );
  const getSellers = useCallback(
    (filters2, postalCode2) => __async(void 0, null, function* () {
      if (requestIsNotAllowed)
        return;
      try {
        dispatch({ type: "REQUEST_PENDING" });
        const orderItems = prescribedItems.map((prescribedItem) => ({
          eans: prescribedItem.eans,
          quantity: prescribedItem.related_type === "WhiteLabels" ? 1 : prescribedItem.quantity,
          prescriptionItemId: prescribedItem.id,
          isSpecialControl: prescribedItem.special_control,
          recipe: prescribedItem.recipe
        })).filter(({ eans }) => eans.length > 0);
        const params = __spreadValues({
          prescriptionToken: token,
          orderItems,
          postalCode: postalCode2
        }, filters2);
        const { data, status } = !filters2.interchange ? yield vtexApi.getSimulate(params) : yield vtexApi.getBestPriceSimulate(params);
        if (status === 200) {
          if (data.sellers && data.sellers.length) {
            const {
              data: { sellers: sellers2 },
              status: status2
            } = yield pbmApi.getEstimate({
              sellers: [...data.sellers].map(({ id, products }) => ({
                id,
                items: products.map(({ ean }) => ean)
              }))
            });
            let hasLabDiscount = false;
            if (status2 === 200) {
              if (sellers2 && sellers2.length) {
                data.sellers = [...data.sellers].map((seller) => {
                  const sellerWithDiscount = sellers2.find(({ id }) => id === seller.id);
                  if (sellerWithDiscount && sellerWithDiscount.items.length) {
                    hasLabDiscount = true;
                  }
                  if (sellerWithDiscount) {
                    seller.products = seller.products.map((product) => __spreadProps(__spreadValues({}, product), {
                      estimate: sellerWithDiscount.items.find((estimate) => estimate.ean === product.ean)
                    }));
                  }
                  return seller;
                });
              }
            }
            xRay.persistData("has_lab_discount", hasLabDiscount);
          }
        }
        xRay.persistData("direct_sale_items_json", normalizeDirectSaleItemsJson(data.sellers));
        dispatch({ type: "REQUEST_SUCCESS", payload: data.sellers });
      } catch (error) {
        if (error.message && error.message === "canceled") {
          throw new Error("Simulate has been canceled");
        }
        console.error("Erro ao buscar eans!", error);
        dispatch({ type: "REQUEST_ERROR" });
      }
    }),
    [requestIsNotAllowed, dispatch, prescribedItems, token]
  );
  useEffect(() => {
    getSellers(filters, postalCode);
    return () => {
      vtexApi.abort();
    };
  }, [filters, postalCode, getSellers]);
  const value = useMemo(
    () => ({
      sellers,
      isLoading,
      hasError,
      filters,
      isSubmitting,
      setFilters,
      setBasket
    }),
    [sellers, isLoading, hasError, filters, isSubmitting, setFilters, setBasket]
  );
  return /* @__PURE__ */ jsx(CartContext.Provider, { value, children: /* @__PURE__ */ jsx(Outlet, {}) });
};
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart can only be used inside a CartProvider!");
  }
  return context;
};
