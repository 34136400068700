"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useReducer } from "react";
const initialState = {
  orderForm: {},
  estimate: {},
  isLoading: true
};
const reducer = (oldState, { type, payload }) => {
  switch (type) {
    case "COMPLETE_PBM":
      return __spreadProps(__spreadValues({}, oldState), {
        orderForm: payload.orderForm,
        estimate: payload.estimate,
        isLoading: false
      });
    default:
      return oldState;
  }
};
export const usePbmReducer = () => useReducer(reducer, initialState);
