"use strict";
import { Service } from "../service";
class PbmApi extends Service {
  constructor() {
    super(process.env.ASSISTANT_API);
  }
  /**
   * Busca a estimativa de descontos
   * @param {IEstimatePayload} payload
   * @returns {Promise}
   */
  getEstimate(payload) {
    return this.instance.post("/v1/pbm/estimate", payload);
  }
  /**
   * Verifica se um usuario ja esta cadastrado no programa de descontos
   * @param {ICustomerSignupPayload} params
   * @returns {Promise}
   */
  getCustomerSignup(params) {
    return this.instance.get("/v1/pbm/customer/signup", {
      params
    });
  }
  /**
   * Cadastra um usuario no programa de descontos
   * @param {ICustomerSignupPayload} payload
   * @returns {Promise}
   */
  setCustomerSignup(payload) {
    return this.instance.post("/v1/pbm/customer/signup", payload);
  }
  /**
   * Verifica se o usuário possui opt-in
   * @param {IOptInVerifyPayload} payload
   * @returns {Promise}
   */
  getOptInVerify(payload) {
    return this.instance.post("/v1/pbm/opt-in/verify", payload);
  }
  /**
   * Verifica se o usuário possui opt-in
   * @param {IOptInVerifyPayload} payload
   * @returns {Promise}
   */
  getOptIn(params) {
    return this.instance.get("/v1/pbm/opt-in", {
      params
    });
  }
  /**
   * Salva o opt-in para do usuário
   * @param {IOptInPayload} payload
   * @returns {Promise}
   */
  setOptIn(payload) {
    return this.instance.post("/v1/pbm/opt-in", payload);
  }
}
export const pbmApi = new PbmApi();
